import React from "react";
import { Close } from "@mui/icons-material";
import { Radio, Modal, Switch, Divider, IconButton } from "@mui/material";

const FiltersModal = (props) => {
  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <div className="filters-modal-container">
        <div className="filters-close-button">
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="filters-modal-title"> Filter Leaderboard</div>
        <div className="filters-modal-following">
          <span>Following</span>
          <Switch
            checked={props.isFollowingChecked}
            onChange={props.handleFollowingChange}
            sx={{ transform: "scale(1.3)" }}
          />
        </div>
        <Divider />
        <div className="filters-modal-status">
          <div className="filters-modal-status-title">By Status</div>
          <div
            role="button"
            tabIndex={0}
            className="filters-modal-status-option"
            onClick={() => props.handleStatusChange("all")}
            onKeyDown={(e) => {
              if (e.key === "Enter") props.handleStatusChange("all");
            }}
          >
            <div className="filters-modal-status-option-text">
              <span>All</span>
            </div>
            <Radio checked={props.selectedStatus == "all"} />
          </div>
          <div
            role="button"
            tabIndex={0}
            className="filters-modal-status-option"
            onClick={() => props.handleStatusChange("qualified")}
            onKeyDown={(e) => {
              if (e.key === "Enter") props.handleStatusChange("qualified");
            }}
          >
            <div className="filters-modal-status-option-text">
              <span>Qualified</span>
              <span>Posted 3+ rounds</span>
            </div>
            <Radio checked={props.selectedStatus == "qualified"} />
          </div>
          <div
            role="button"
            tabIndex={0}
            className="filters-modal-status-option"
            onClick={() => props.handleStatusChange("in_progress")}
            onKeyDown={(e) => {
              if (e.key === "Enter") props.handleStatusChange("in_progress");
            }}
          >
            <div className="filters-modal-status-option-text">
              <span>In-progress</span>
              <span>Posted less than 3 rounds</span>
            </div>
            <Radio checked={props.selectedStatus == "in_progress"} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FiltersModal;
