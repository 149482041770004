import ContactUs from "../../screens/contact-us/components/ContactUs";
import GhinAssociations from "../../screens/ghin-associations/components/GhinAssociations";
import AboutGhin from "../../screens/about-ghin/components/AboutGhin";
import Challenge from "../../screens/challenges/Challenge";
import DefaultLayout from "../components/layouts/DefaultLayout";
import Navigate from "../navigation/navigate";
import EndOfYearCampaign from "../../screens/end-of-year-campaign/components/EndOfYearCampaign";
import NoFooterAndHeaderLayout from "../components/layouts/NoFooterAndHeaderLayout";
import { GHIN_REWIND_YEAR } from "../../screens/end-of-year-campaign/year-end-constants";

export default {
  contactUs: {
    path: "/contact-us",
    component: ContactUs,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: (params) => Navigate.toPath(`/contact-us?${params}`),
  },
  ghinAssociations: {
    path: "/ghin-associations",
    component: GhinAssociations,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
    navigate: () => Navigate.toPath("/ghin-associations"),
  },
  aboutUs: {
    path: "/about-us",
    component: AboutGhin,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
  },
  challenges: {
    path: "/leaderboard",
    component: Challenge,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: DefaultLayout,
  },
  endOfYearCampaign: {
    path: "/end-of-year-campaign",
    component: EndOfYearCampaign,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: NoFooterAndHeaderLayout,
  },
  shareEndOfYearImage: {
    path: "/" + GHIN_REWIND_YEAR + "-rewind",
    component: EndOfYearCampaign,
    exact: false,
    rootPath: true,
    authRequired: false,
    layout: NoFooterAndHeaderLayout,
    navigate: (image_url) =>
      Navigate.toPath(`/${GHIN_REWIND_YEAR}-rewind/${image_url}`),
  },
};
