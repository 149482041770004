import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import pagesPaths from "../../../shared/routes/pages-paths";

class Links extends Component {
  state = {
    isUSUser: false,
    loading: true,
  };

  componentDidMount() {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          isUSUser: data.country === "US",
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching geolocation data:", error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { isUSUser, loading } = this.state;

    return (
      <ul className="footer__links">
        <li>
          <a
            className="footer__link"
            href="http://www.usga.org/handicapping/ghin-faqs.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQs
          </a>
        </li>
        <li>
          <NavLink
            className="footer__link"
            activeClassName="is-active"
            to={pagesPaths.contactUs.path}
          >
            CONTACT&nbsp;
            <span aria-hidden="true">US</span>
            <span className="visually-hidden">us</span>
          </NavLink>
        </li>
        <li>
          <a
            className="footer__link"
            href="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRIVACY POLICY
          </a>
        </li>
        <li>
          <a
            className="footer__link"
            href="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            TERMS OF SERVICE
          </a>
        </li>
        {!loading && isUSUser && (
          <li>
            <button
              id="ot-sdk-btn"
              className="visually-hidden ot-sdk-show-settings"
            ></button>
            <textarea
              style={{
                border: "none",
                outline: "none",
                height: "20px",
                resize: "none",
                cursor: "pointer",
                fontFamily: "National",
              }}
              className="footer__link"
              onClick={() => {
                document.getElementById("ot-sdk-btn").click();
                return false;
              }}
              value="YOUR PRIVACY CHOICES"
              readOnly={true}
            ></textarea>
          </li>
        )}
        <li>
          <a
            className="footer__link"
            href="https://www.usga.org/content/usga/home-page/usga-accessibility-statement.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACCESSIBILITY
          </a>
        </li>
      </ul>
    );
  }
}
export default Links;
