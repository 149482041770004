import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

class RegistrationForm extends Component {
  state = {
    showPassword: { password: false, confirmPassword: false },
    errors: false,
  };

  validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      //.min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirming password is required"),
    terms: Yup.bool()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),
  });

  formRef = React.createRef();

  handleGoBack = (e) => {
    e.preventDefault();
    const formData = new FormData(this.formRef.current);
    this.props.onGoBack(Object.fromEntries(formData));
  };

  handleOnClick = (e, valid) => {
    if (!valid) {
      this.setState({
        errors: true,
      });
      e.preventDefault();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(this.formRef.current);
    const combinedData = {
      ...this.props.initialData,
      ...Object.fromEntries(formData),
    };
    this.props.onSubmit(combinedData);
  };

  onToggleShowPassword = (p) => {
    var temp =
      p == "password"
        ? {
            password: !this.state.showPassword["password"],
            confirmPassword: this.state.showPassword["confirmPassword"],
          }
        : {
            password: this.state.showPassword["password"],
            confirmPassword: !this.state.showPassword["confirmPassword"],
          };
    this.setState({ showPassword: temp });
  };

  renderForm = (options) => (
    <>
      <div className="registration_form trial_form">
        <form ref={this.formRef} onSubmit={this.handleSubmit}>
          {this.renderTextInput("email", "EMAIL ADDRESS", options)}
          {this.renderPasswordInput("password", "SET A PASSWORD", options)}
          {this.renderPasswordInput(
            "confirmPassword",
            "CONFIRM PASSWORD",
            options
          )}
          {this.renderCheckbox("terms", options)}
          <button
            type="submit"
            className="btn continue-button"
            disabled={this.props.isSubmitting}
            onClick={(e) => {
              this.handleOnClick(e, options.isValid);
            }}
            style={{
              width: "50%",
              margin: "auto",
              color: "white",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Continue
          </button>
          <br></br>
          <div className="row">
            <div className="col is-full">
              <button
                className="lnk white color-focus"
                onClick={this.handleGoBack}
                style={{
                  textAlign: "center",
                  color: "dodgerblue",
                  fontWeight: "600",
                  background: "white",
                  border: "none",
                  fontFamily: "arial, sans-serif",
                  cursor: "pointer",
                }}
              >
                GO BACK
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );

  renderTextInput = (name, label, options) => (
    <div style={{ height: "90px" }}>
      <label
        htmlFor={name}
        style={{
          fontFamily: "National",
          fontSize: "14px",
          color: "#000000",
        }}
      >
        {label}
      </label>
      <input
        type="email"
        autoComplete="off"
        id={name}
        name={name}
        value={options.values[name]}
        onChange={options.handleChange}
        onBlur={options.handleBlur}
        aria-describedby={`${name}Error`}
        style={{ marginBottom: "0px" }}
        aria-invalid={options.errors[name] ? "true" : "false"}
        aria-required="true"
      />
      <span className="error" id={`${name}Error`}>
        {options.touched[name] || this.state.errors ? options.errors[name] : ""}
      </span>
    </div>
  );

  renderPasswordInput = (name, label, options) => (
    <div style={{ height: "90px" }}>
      <label
        htmlFor={name}
        style={{
          fontFamily: "National",
          fontSize: "14px",
          color: "#000000",
        }}
      >
        {label}
      </label>
      <div className="show_password_wrapper">
        <input
          type={this.state.showPassword[name] ? "text" : "password"}
          id={name}
          name={name}
          value={options.values[name]}
          onChange={options.handleChange}
          style={{ marginBottom: "0px" }}
          onBlur={options.handleBlur}
          aria-describedby={`${name}Error`}
          aria-invalid={options.errors[name] ? "true" : "false"}
          aria-required="true"
        />
        {options.values[name] && options.values[name].length > 0 && (
          <div
            className="show_password"
            style={{
              paddingRight: "5px",
              display: "flex",
              alignItems: "center",
              height: "70%",
              margin: "5px 5px 5px 5px",
              paddingLeft: "5px",
              backgroundColor: "white",
            }}
          >
            <button
              type="button"
              onClick={() => {
                this.onToggleShowPassword(name);
              }}
              className="btn lnk no-underline show_button border-focus"
              style={{
                height: "10px",
                alignItems: "center",
                marginTop: "0px",
                display: "flex",
                paddingRight: "10px",
                paddingTop: "11px",
              }}
            >
              {this.state.showPassword[name] ? "HIDE" : "SHOW"}
            </button>
          </div>
        )}
      </div>
      <span className="error" id={`${name}Error`}>
        {options.touched[name] || this.state.errors ? options.errors[name] : ""}
      </span>
    </div>
  );

  renderCheckbox = (name, options) => (
    <div
      className="checkboxWrapper"
      style={{
        marginTop: "15px",
        marginLeft: "-5px",
      }}
    >
      <div className="checkboxLabelContainer">
        <input
          type="checkbox"
          id={name}
          style={{
            position: "relative",
            left: "0px",
            width: "20%",
          }}
          name={name}
          onChange={options.handleChange}
          checked={options.values[name]}
        />
        <label htmlFor={name} className="checkboxLabel">
          By registering for a GHIN Trial and creating a GHIN Account, I verify
          that I am 13 years of age or older, I accept and agree to the
          <a
            style={{
              color: "#0088ce",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.usga.org/content/usga/home-page/usga-apps/ghin/trial-terms-conditions.html"
          >
            {" GHIN Trial Terms & Conditions"}
          </a>
          ,
          <a
            style={{
              color: "#0088ce",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html"
          >
            {" GHIN Terms of Service"}
          </a>
          , and that my information will be processed in accordance with the
          <a
            style={{
              color: "#0088ce",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-privacy-policy.html"
          >
            {" GHIN Privacy Policy"}
          </a>
          .
        </label>
      </div>
      <span className="error" id={`${name}Error`}>
        {options.touched[name] ? options.errors[name] : ""}
      </span>
    </div>
  );

  render() {
    return (
      <Formik
        initialValues={{
          email: this.props.oldData.email || "",
          password: this.props.oldData.password || "",
          confirmPassword: this.props.oldData.confirmPassword || "",
          terms: false,
        }}
        validationSchema={this.validationSchema}
        onSubmit={this.onSubmit}
      >
        {this.renderForm}
      </Formik>
    );
  }
}

RegistrationForm.propTypes = {
  registration: PropTypes.func.isRequired,
  // Removed postStatus since it's not used in this updated form
};

export default RegistrationForm;
