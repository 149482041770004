import authPaths from "../../../shared/routes/auth-paths";
import Navigate from "../../../shared/navigation/navigate";
import { rememberMe } from "../../../shared/helpers/local-storage";
import { fetchCountries } from "../../golfers/actions/countries-states-fetch";
import { mergeMinorGolfer } from "../../../shared/helpers/user-helper";
import GolferApi from "../../../services/golfer-api";
import { encrypt } from "../../../shared/helpers/encoder";

import { fetchHomeCourses } from "../actions/home-courses";
import { fetchGolferProfile } from "../../profile/actions/golfer-profile-fetch";
import { fetchContactAssociations } from "../../profile/actions/golfer-associations-fetch";
import { completeLogout } from "./api-login-methods.js";

export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const PERSIST_INITIAL_LOGIN = "PERSIST_INITIAL_LOGIN";
export const PERSIST_REFRESH_LOGIN = "PERSIST_REFRESH_LOGIN";

export const LOGOUT = "LOGOUT";
export const RESET_FORM = "RESET_FORM";

//Terms
export const WAIT_TO_ACCEPT_TERMS = "WAIT_TO_ACCEPT_TERMS";
export const USER_ACCEPTED_TERMS = "USER_ACCEPTED_TERMS";
export const USER_DENIED_TERMS = "USER_DENIED_TERMS";

//Select golfers
export const WAIT_TO_SELECT_GOLFER = "WAIT_TO_SELECT_GOLFER";
export const SELECT_GOLFER = "SELECT_GOLFER";
export const UPDATE_MINORS = "UPDATE_MINORS";
export const START_FETCH = "START_FETCH";
export const STOP_FETCH = "STOP_FETCH";

export const updateMinors = (payload) => ({
  type: UPDATE_MINORS,
  payload,
});

export const login = (password, emailOrGhin, remember_me) => ({
  type: LOGIN,
  payload: { password, emailOrGhin, remember_me },
});

export const loginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const resetForm = () => ({ type: RESET_FORM });

export const waitForAcceptTerms = (payload) => ({
  type: WAIT_TO_ACCEPT_TERMS,
  payload,
});

export function userAcceptedTerms(golfers, minors) {
  return (dispatch) => {
    dispatch({ type: USER_ACCEPTED_TERMS });
    dispatch(logInUser(golfers, minors));
  };
}

export const userDeniedTerms = () => ({ type: USER_DENIED_TERMS });

export const persistInitialLogin = (payload) => ({
  type: PERSIST_INITIAL_LOGIN,
  payload,
});

export const persistRefreshLogin = (payload) => ({
  type: PERSIST_REFRESH_LOGIN,
  payload,
});

export const waitToSelectGolfer = () => ({ type: WAIT_TO_SELECT_GOLFER });

export function selectGolfer(payload) {
  const assocParams = {
    ghinNumber: payload.ghin_number,
    memberships: [], // always loaded in fetchGolferProfileSuccess after selecting the golfer
  };

  return (dispatch) => {
    dispatch({ type: SELECT_GOLFER, payload });
    if (payload.status !== "Archived") {
      dispatch(
        fetchGolferProfile({
          golfer_id: payload.ghin_number,
          last_name: payload.last_name,
        })
      );
      dispatch(fetchHomeCourses());
      dispatch(fetchCountries());
      dispatch(fetchContactAssociations(assocParams));
    }
  };
}

export function loadMinorGolfer(payload) {
  return (dispatch) => {
    dispatch({ type: START_FETCH });
    GolferApi.searchMinorGolfer(payload.id)
      .then((response) => {
        dispatch({ type: STOP_FETCH });
        dispatch(selectGolfer(mergeMinorGolfer(response.data.golfers[0])));
      })
      .catch(() => {
        dispatch({ type: STOP_FETCH });
        dispatch(completeLogout()).then(() => {
          Navigate.resetToPath(authPaths.login.path);
        });
      });
  };
}

function logInUser(golfers, minors) {
  return (dispatch) => {
    if (minors && minors.length > 0) {
      if (minors.length < 2 && golfers.length === 0) {
        dispatch(loadMinorGolfer(minors[0]));
      } else {
        dispatch(waitToSelectGolfer());
      }
    } else {
      dispatch(selectGolfer(golfers[0]));
    }
  };
}

// function refreshLoginSucces(response) {
//   setTimeout(() => {
//     location.reload();
//   }, 250);
//   return (dispatch) => {
//     dispatch(persistRefreshLogin({ ...response }));
//   };
// }

export function loginSuccess(response, payloadLogin) {
  rememberMe(payloadLogin);
  return (dispatch) => {
    const { golfer_user } = response;
    payloadLogin.password = encrypt(payloadLogin.password);
    if (golfer_user) {
      dispatch(
        persistInitialLogin({ ...response.golfer_user, ...payloadLogin })
      );
      if (!golfer_user.golfer_user_accepted_terms) {
        dispatch(waitForAcceptTerms(golfer_user));
      } else {
        dispatch(
          logInUser(
            response.golfer_user.golfers,
            response.golfer_user.minor_accounts
          )
        );
      }
    }
  };
}
