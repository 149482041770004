/* eslint-disable */
import React, { useMemo, useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import GolferApi from "../../services/golfer-api";
import { Centrifuge } from "centrifuge";
import { Star, StarOutline } from "@mui/icons-material";
import FiltersModal from "./FiltersModal";
import ChallengeHeader from "./ChallengeHeader";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"National", Arial, sans-serif',
  },
});

export default function Challenge() {
  const [currentUser, setCurrentUser] = useState(false);
  const [localStorageToken, setLocalStorageToken] = useState(null);
  const centrifugeRef = useRef(null);
  const subRef = useRef(null);
  const pageRef = useRef(1);
  const [userName, setUserName] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [perPage, setPerPage] = useState(20); // Load 20 rows initially
  const [preview, setPreview] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [challengeName, setChallengeName] = useState("");
  const [daysLeft, setDaysLeft] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const [selectedStatus, setSelectedStatus] = useState("all"); // Stores single selected status
  const [isFollowingChecked, setIsFollowingChecked] = useState(false); // Following checkbox state

  const [isRowPinned, setIsRowPinned] = useState(true);
  const userRowRef = useRef(null);
  const tableContainerRef = useRef(null);

  const computed_name =
    currentUser && isSmallScreen
      ? "GOLFER (H.I.)"
      : !currentUser
      ? "GOLFER"
      : "GOLFER (HANDICAP INDEX)";

  const getPrivateToken = async (pageNo) => {
    const path = window.location.pathname;
    const pathParts = path.split("/");
    const idFromUrl = pathParts[pathParts.length - 1];
    const response = await GolferApi.getPrivateLeaderboardToken(pageNo, idFromUrl);
    console.log(response.data.token);
    return response.data.token;
  };

  const getToken = async () => {
    const response = await GolferApi.getLeaderboardToken();
    return response.data.token;
  };

  const ws_connection = (pageNo = 0) => {
    const path = window.location.pathname;
    const pathParts = path.split("/");
    const idFromUrl = pathParts[pathParts.length - 1];

    const init = async () => {
      centrifugeRef.current = new Centrifuge(
        "wss://stg-ghin-wss.hcp2020.com/connection/websocket",
        {
          getToken: getToken,
        }
      );
      centrifugeRef.current.connect();
    };
    init();
    centrifugeRef.current
      .on("connecting", function (ctx) {
        console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
      })
      .on("connected", function (ctx) {
        console.log(`connected over ${ctx.transport}`);
      })
      .on("disconnected", function (ctx) {
        console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
      })
      .on("unsubscribed", function (ctx) {
        console.log("unsubscribed from server-side channel", ctx.channel);
      })
      .connect();
    if (userName) {
      subRef.current = centrifugeRef.current.newSubscription(
        "$leaderboard:" + idFromUrl + ":private",
        { getToken: () => getPrivateToken(pageNo) }
      );
      centrifugeRef.current
        .presence("$leaderboard:" + idFromUrl + ":private")
        .then(
          function (resp) {
            console.log(resp);
          },
          function (err) {
            console.log("presence error", err);
          }
        );
    } else {
      subRef.current = centrifugeRef.current.newSubscription(
        "leaderboard:" + idFromUrl + ":public"
      );
      centrifugeRef.current
        .presence("leaderboard:" + idFromUrl + ":public")
        .then(
          function (resp) {
            console.log(resp);
          },
          function (err) {
            console.log("presence error", err);
          }
        );
    }
    subRef.current
      .on("publication", function (ctx) {
        getData(true);
      })
      .on("subscribing", function (ctx) {
        console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
      })
      .on("subscribed", function (ctx) {
        console.log("subscribed", ctx);
      })
      .on("unsubscribed", function (ctx) {
        console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
      })
      .subscribe();
  };

  useEffect(() => {
    const token = localStorage.getItem("ghin-user-token");
    setLocalStorageToken(token);

    // check params
    const params = new URLSearchParams(window.location.search);
    if (params.has("per_page")) {
      setPerPage(Number(params.get("per_page")));
    }
    if (params.has("preview")) {
      setPreview(params.get("preview"));
    }

    const handleResize = () => setIsSmallScreen(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    ws_connection(0);
    return () => {
      if (centrifugeRef.current) {
        centrifugeRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    // Update the Leaderboard on pagination and filters changes
    getData();
  }, [selectedStatus, isFollowingChecked, page, perPage]);

  useEffect(() => {
    // Render or remove the LoadMoreButton on pagination changes
    if (page < pages && !preview) handleRenderLoadMore(page);
    else document.querySelector("#loadButton-container")?.remove();
  }, [page, perPage, pages, preview]);

  // When isRowPinned becomes false, scroll the (unpinned) user row into view.
  useEffect(() => {
    if (!isRowPinned && userRowRef.current && tableContainerRef.current) {
      // Scroll the user row into view (its natural position in the table body)
      userRowRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

      // Attach a scroll event listener to re-pin once the user scrolls.
      const container = tableContainerRef.current;
      const handleScroll = () => {
        setIsRowPinned(true);
        container.removeEventListener("scroll", handleScroll);
      };
      container.addEventListener("scroll", handleScroll);

      // Fallback: re-pin after 1 second if no scroll event occurs.
      const timeoutId = setTimeout(() => {
        setIsRowPinned(true);
        container.removeEventListener("scroll", handleScroll);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isRowPinned]);

  const getData = (all = false) => {
    let status = selectedStatus.toLowerCase();
    let params = all
      ? { page: 1, per_page: perPage * pageRef.current, status }
      : { page, per_page: perPage, status };

    if (isFollowingChecked) params["following"] = true;

    const path = window.location.pathname;
    const pathParts = path.split("/");
    const idFromUrl = pathParts[pathParts.length - 1];
    GolferApi.getData(idFromUrl, params)
      .then((res) => {
        if (res.data.mode) {
          setCurrentUser(res.data.current_user);
          setUserName(res.data.username);
        }

        let leaderboardData = [];
        if (all || page === 1) leaderboardData = res.data.leaderboard;
        else {
          leaderboardData = data.concat(res.data.leaderboard);
          let duplicatePositionIndex = -1;
          if (res.data.leaderboard[0]?.ghin_number === res.data.current_user)
            duplicatePositionIndex = leaderboardData.findLastIndex(
              (e) => e.ghin_number === res.data.current_user
            );
          else
            duplicatePositionIndex = leaderboardData.findIndex(
              (e) => e.ghin_number === res.data.current_user
            );
          // Remove duplicate position of the current user
          leaderboardData.splice(duplicatePositionIndex, 1);
        }

        setData(leaderboardData);
        setChallengeName(res.data.name);
        setDaysLeft(res.data.days_left);
        setPages(parseInt(res.headers["total-pages"], 10));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRenderLoadMore = (page) => {
    let buttonContainer = document.querySelector("#loadButton-container");
    let root;
    if (buttonContainer) {
      root = createRoot(buttonContainer);
      root.render(<LoadMoreButton page={page} />);
    } else {
      const tableElement = document.querySelector(".MuiTable-root");
      if (!tableElement) return;
      // Create a container
      buttonContainer = document.createElement("div");
      buttonContainer.setAttribute("id", "loadButton-container");
      // Append the container to the table element's parent
      tableElement.parentElement.appendChild(buttonContainer);
      root = createRoot(buttonContainer);
      root.render(<LoadMoreButton page={page} />);
    }
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    setPage(1);
  };

  const handleFollowingChange = () => {
    const newValue = !isFollowingChecked;
    setIsFollowingChecked(newValue);
    setPage(1);
  };

  const handleLoadMore = () => {
    pageRef.current += 1;
    setPage(page + 1);
  };

  const handlePinnedRowClick = () => {
    setIsRowPinned(false);
  };

  const LoadMoreButton = ({ page }) => (
    <div className="row" style={{ height: "40px", margin: "20px" }}>
      <div className="col auto h-centered">
        <button
          className="btn outline blue"
          id="LoadMore-btn"
          onClick={() => handleLoadMore(page)}
        >
          Load More
        </button>
      </div>
    </div>
  );

  const tabelHeightCalc = () => {
    if (preview) return 0;
    if (isFollowingChecked === false && selectedStatus === "all") return 134;
    return 166;
  };

  const rowPinningState =
    userName && data.some((row) => row.name === userName) && isRowPinned
      ? { top: [userName] }
      : { top: [] };

  const columns = useMemo(() => {
    const round_size = isSmallScreen ? 35 : 70;
    const position_size = isSmallScreen ? 35 : 90;
    const baseColumns = [
      {
        accessorKey: "position",
        header: isSmallScreen ? "POS" : "POSITION",
        enableSorting: false,
        enableFiltering: false,
        enableColumnActions: false,
        grow: false,
        size: position_size,
        Cell: ({ row }) => (
          <div className="row">
            <span className="position-cell" style={{ fontSize: "16px"}}>
              <div className="col is-1-of-2">
                {row.original.position_display}
              </div>
              {currentUser && !isSmallScreen && (
                <div className="col is-2-of-2">
                  {row.original.followed === true ? (
                    <Star className="icon-star" />
                  ) : (
                    <StarOutline className="icon-star-outline" />
                  )}
                </div>
              )}
            </span>
          </div>
        ),
        muiTableHeadCellProps: { className: "header-position" },
        muiTableBodyCellProps: { className: "body-position" },
      },
      {
        accessorKey: "name",
        header: computed_name,
        enableSorting: false,
        enableFiltering: false,
        enableColumnActions: false,
        size: 120,
        Cell: ({ row }) => (
          <span className="name-cell">
            <span className="name-display">
              {row.original.short_name && isSmallScreen
                ? row.original.short_name
                : row.original.name}
            </span>
            {currentUser && (
              <span className="handicap-display">
                {row.original.hi_display}
              </span>
            )}
          </span>
        ),
        muiTableHeadCellProps: { className: "header-name" },
        muiTableBodyCellProps: { className: "body-name" },
      },
      {
        accessorKey: "r1",
        header: isSmallScreen ? "R1" : "RD1",
        enableSorting: false,
        enableFiltering: false,
        enableColumnActions: false,
        size: round_size,
        grow: false,
        Cell: ({ row }) => (
          <span className="round-cell">{row.original.r1}</span>
        ),
        muiTableHeadCellProps: {
          className: "header-round",
          sx: {
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "center",
              fontWeight: "500",
              color: "rgba(16, 24, 40, 1)",
            },
          },
        },
        muiTableBodyCellProps: { className: "body-round" },
      },
      {
        accessorKey: "r2",
        header: isSmallScreen ? "R2" : "RD2",
        enableSorting: false,
        enableFiltering: false,
        enableColumnActions: false,
        size: round_size,
        grow: false,
        Cell: ({ row }) => (
          <span className="round-cell">{row.original.r2}</span>
        ),
        muiTableHeadCellProps: {
          className: "header-round",
          sx: {
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "center",
              fontWeight: "500",
              color: "rgba(16, 24, 40, 1)",
            },
          },
        },
        muiTableBodyCellProps: { className: "body-round" },
      },
      {
        accessorKey: "r3",
        header: isSmallScreen ? "R3" : "RD3",
        enableSorting: false,
        enableFiltering: false,
        enableColumnActions: false,
        size: round_size,
        grow: false,
        Cell: ({ row }) => (
          <span className="round-cell">{row.original.r3}</span>
        ),
        muiTableHeadCellProps: {
          className: "header-round",
          sx: {
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "center",
              fontWeight: "500",
              color: "rgba(16, 24, 40, 1)",
            },
          },
        },
        muiTableBodyCellProps: { className: "body-round" },
      },
      {
        accessorKey: "total",
        header: isSmallScreen ? "Total" : "TOTAL",
        enableSorting: false,
        enableFiltering: false,
        enableColumnActions: false,
        size: round_size,
        grow: false,
        Cell: ({ row }) => (
          <span
            className={
              row.original.total?.includes("-")
                ? "total-negative"
                : "total-positive"
            }
            style={{ fontWeight: "100", fontFamily: "National Narrow Bold", }}
          >
            {row.original.total}
          </span>
        ),
        muiTableHeadCellProps: {
          className: "header-total",
          sx: {
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "center",
              fontWeight: "700",
              color: "rgba(16, 24, 40, 1)",
            },
          },
        },
        muiTableBodyCellProps: {
          className: "body-total",
          sx: {
          },
        },
      },
    ];
    // If no current user, reorder columns.
    if (!currentUser) {
      return [
        baseColumns[0], // position
        baseColumns[1], // name
        baseColumns[5], // total
        baseColumns[2], // r1
        baseColumns[3], // r2
        baseColumns[4], // r3
      ];
    }
    // Default order when current user exists.
    return baseColumns;
  }, [isSmallScreen, currentUser, computed_name]);

  // Create the table instance.
  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: "grid",
    defaultColumn: { minSize: 35 },
    enablePagination: false,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    getRowId: (row) => row.name,
    initialState: {
      density: "compact",
      expanded: true,
    },
    state: { rowPinning: rowPinningState },
    enableColumnFilterModes: false,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTableHeadRowProps: {
      sx: { boxShadow: "none" },
    },
    muiTablePaperProps: {
      sx: { boxShadow: "none" },
    },
    muiTableBodyRowProps: ({ row }) => {
      const isCurrentUserRow = row.id === userName;
      return {
        sx: {
          height: isSmallScreen ? "43px" : "77px",
          bottom: 0,
          top: isCurrentUserRow ? (isRowPinned ? "34px" : 0) : 0,
          zIndex: isCurrentUserRow ? 2 : 0,
          position: isCurrentUserRow
            ? isRowPinned
              ? "sticky"
              : "relative"
            : "relative",
          backgroundColor: isCurrentUserRow
            ? "rgb(237, 243, 247)"
            : undefined,
          cursor: isCurrentUserRow && isRowPinned ? "pointer" : "default",
        },
        onClick: isCurrentUserRow && isRowPinned ? handlePinnedRowClick : undefined,
        ref: !isRowPinned && isCurrentUserRow ? userRowRef : undefined,
      };
    },
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: {
        height: `calc(100vh - ${tabelHeightCalc()}px)`,
        position: "relative",
        padding: "0 10px",
        "@media (max-width: 600px)": {
          maxWidth: "100%",
          overflowX: "auto",
          fontSize: "6px",
        },
        "@media (min-width: 601px)": {
          maxWidth: "100%",
          fontSize: "10px",
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="challenge-container">
        {!preview && (
          <ChallengeHeader
            challengeName={challengeName}
            daysLeft={daysLeft}
            currentUser={currentUser}
            isSmallScreen={isSmallScreen}
            setModalOpen={setModalOpen}
            handleFollowingChange={handleFollowingChange}
            isFollowingChecked={isFollowingChecked}
            handleStatusChange={handleStatusChange}
            selectedStatus={selectedStatus}
          />
        )}
        <MaterialReactTable table={table} />
        <FiltersModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          isFollowingChecked={isFollowingChecked}
          handleFollowingChange={handleFollowingChange}
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
        />
      </div>
    </ThemeProvider>
  );
}
